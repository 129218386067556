import { Alert, Drawer } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { RobotoTypography } from "@/shared/components/RobotoTypography";
import { ResizeSeparatorProps } from "@/shared/hooks";

import { EventDetails } from "../EventDetails";
import { Toolbar } from "../EventDetails/Toolbar";
import { PanelSettings } from "../PanelSettings";
import { WorkspaceResizeHandle } from "../resize-handles";
import {
  isPanelSettingsView,
  SidebarViewType,
  useWorkspaceSidebar,
  isTabbedSidebarView,
  isCreateEventView,
} from "../sidebarContext";
import { isEventDetailsView } from "../sidebarContext/sidebarView";
import { useEphemeralWorkspaceState } from "../WorkspaceCtx";

import { CreateEventSidebar } from "./CreateEventSidebar";
import styles from "./Sidebar.module.css";
import { SidebarItem } from "./SidebarItem";
import { TabbedSidebar } from "./TabbedSidebar";

interface SidebarProps {
  style?: React.CSSProperties;
}

interface WorkspaceSidebarProps extends SidebarProps {
  isDragging: boolean;
  separatorProps: ResizeSeparatorProps;
  style?: React.CSSProperties;
  resizeHandleRef: React.RefObject<HTMLElement>;
}

function CreateEventDrawer() {
  const sidebar = useWorkspaceSidebar();

  const closeDrawer = () => {
    sidebar.toggleDrawer(null);
  };

  // Empty sidebar content. We shouldn't ever reach here.
  return (
    <Drawer
      open={isCreateEventView(sidebar.activeDrawer)}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          width: 500,
        },
      }}
    >
      <CreateEventSidebar onClose={closeDrawer} />
    </Drawer>
  );
}

function TabSidebar(props: SidebarProps) {
  const sidebar = useWorkspaceSidebar();
  if (isTabbedSidebarView(sidebar.activeView)) {
    return (
      <TabbedSidebar
        {...(sidebar.activeView.data || {})}
        containerStyle={props.style}
      />
    );
  }

  return null;
}

function EventDetailsSidebar(props: SidebarProps) {
  const sidebar = useWorkspaceSidebar();
  const [error, setError] = React.useState<Error | null>(null);

  if (isEventDetailsView(sidebar.activeView) === false) {
    return null;
  }

  const closeEventDetails = () => {
    sidebar.openView({
      type: SidebarViewType.TabbedSidebar,
      data: undefined,
    });
  };

  return (
    <SidebarItem
      containerStyle={props.style}
      header={
        <RobotoTypography
          component="h2"
          sx={{ fontSize: "1.05rem", fontWeight: 500 }}
        >
          Event Details
        </RobotoTypography>
      }
      body={
        <>
          {error && (
            <Alert
              onClose={() => setError(null)}
              severity="error"
              sx={{ marginBottom: "0.5rem" }}
            >
              {error.message}
            </Alert>
          )}
          <EventDetails eventId={sidebar.activeView.data.eventId} />
        </>
      }
      footer={
        <Toolbar
          eventId={sidebar.activeView.data.eventId}
          onClose={closeEventDetails}
          onError={setError}
        />
      }
      onClose={closeEventDetails}
    />
  );
}

function PanelSettingSidebar(props: SidebarProps) {
  const workspace = useEphemeralWorkspaceState();
  const sidebar = useWorkspaceSidebar();

  if (!isPanelSettingsView(sidebar.activeView)) {
    return null;
  }

  return (
    <SidebarItem
      containerStyle={props.style}
      header={
        <RobotoTypography
          component="h2"
          sx={{ fontSize: "1.25rem", fontWeight: 500 }}
        >
          Panel Settings
        </RobotoTypography>
      }
      body={<PanelSettings />}
      onClose={function closePanelSettings() {
        sidebar.openView({
          type: SidebarViewType.TabbedSidebar,
          data: undefined,
        });
        workspace.clearPanelSelection();
      }}
    />
  );
}

export function Sidebar(props: WorkspaceSidebarProps) {
  const sidebar = useWorkspaceSidebar();
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.resizing]: props.isDragging },
        { [styles.hide]: sidebar.activeView.type === SidebarViewType.None },
      )}
      style={props.style}
    >
      <CreateEventDrawer />
      <EventDetailsSidebar />
      <TabSidebar />
      <PanelSettingSidebar />
      <WorkspaceResizeHandle
        isDragging={props.isDragging}
        separatorProps={props.separatorProps}
        ref={props.resizeHandleRef}
      />
    </div>
  );
}
