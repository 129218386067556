import { Alert, AlertTitle, Box, Paper, useTheme } from "@mui/material";
import React from "react";

import { EventDetails } from "@/features/dataset/components/DatasetEvents/EventDetails";
import { EventRecord } from "@/shared/domain/events";
import { SearchQueryBody } from "@/types";

import { RobotoDataGrid } from "../RobotoDataGrid";

import { ColumnsModal } from "./ColumnsModal";
import { tablePageReducer } from "./stateReducer";
import { createRows, retrieveMetadataColumns } from "./tableLogic";

const doNothing = () => {};

const initialEventQuery: SearchQueryBody = {
  sort_by: "created",
  sort_direction: "DESC",
};

const possibleStandardEventColumns = [
  "event_id",
  "name",
  "description",
  "start_time",
  "end_time",
  "created",
  "created_by",
  "modified",
  "modified_by",
  "tags",
];

const sortableColumns = new Set<string>();

interface EventResultsTableProps {
  events: EventRecord[];
  loading: boolean;
  containerStyle?: React.CSSProperties;
}

export const EventResultsTable: React.FC<EventResultsTableProps> = (
  props: EventResultsTableProps,
) => {
  const { events, loading, containerStyle } = props;

  const theme = useTheme();

  const eventsById = new Map<string, EventRecord>(
    events.map((event) => {
      return [event.event_id, event];
    }),
  );

  const [state, dispatch] = React.useReducer(tablePageReducer, {
    modalOpen: false,
    rightClickedRow: null,
    sidebar: { isOpen: false, dataset: null },
    columns: ["event_id", "name", "start_time", "created", "tags"],
    tableConfig: { page: 0, rowsPerPage: 25 },
    snackbar: { isOpen: false, message: "" },
    sortColumnIndex: 1,
    sortOrder: "descending",
    lastSearchQuery: initialEventQuery,
    selectedRows: new Set<string>(),
  });

  const pageData = React.useMemo(() => {
    return events.slice(
      state.tableConfig.page * state.tableConfig.rowsPerPage,
      state.tableConfig.page * state.tableConfig.rowsPerPage +
        state.tableConfig.rowsPerPage,
    );
  }, [events, state.tableConfig]);

  const { rows, allPossibleColumns } = React.useMemo(() => {
    const possibleMetadataColumns: string[] =
      retrieveMetadataColumns(pageData) ?? [];

    const rows = createRows(pageData, state.columns, possibleMetadataColumns);

    const allPossibleColumns = possibleStandardEventColumns
      .concat(possibleMetadataColumns)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

    return { rows, allPossibleColumns };
  }, [pageData, state.columns]);

  const noSearchResults =
    Object.keys(state.lastSearchQuery).length > 0 && events.length === 0;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: "100%",
        }}
      >
        <Paper
          sx={{
            mt: theme.spacing(2),
          }}
        >
          {noSearchResults && (
            <Alert severity="info">
              <AlertTitle>Events</AlertTitle>
              <>
                No events match your search criteria. Try changing your search
                terms and try again.
              </>
            </Alert>
          )}

          {!noSearchResults && (
            <>
              <RobotoDataGrid
                sortableColumns={sortableColumns}
                sortingOrder={state.sortOrder}
                sortingColumnIndex={state.sortColumnIndex}
                page={state.tableConfig.page}
                rowsPerPage={state.tableConfig.rowsPerPage}
                nextPageButtonDisabled={
                  (state.tableConfig.page + 1) *
                    state.tableConfig.rowsPerPage >=
                  events.length
                }
                currentRowLength={events.length}
                loading={loading}
                containerStyle={containerStyle}
                columnNames={state.columns}
                rows={rows}
                onPageChange={(newPage, rowsPerPage) => {
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: { page: newPage, rowsPerPage: rowsPerPage },
                  });
                }}
                onRowsPerPageChange={(_page, newRowsPerPage) => {
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: {
                      page: 0,
                      rowsPerPage: newRowsPerPage as 10 | 25 | 50,
                    },
                  });
                }}
                onColumnSortClicked={doNothing}
                onAddColumnClick={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: true });
                }}
                onRowSingleClick={doNothing}
                onRowDoubleClick={doNothing}
                onSelectedRowsChange={doNothing}
                isRowSelectable={false}
                isRowExpandable={true}
                clearSelectedRowsToggle={false}
                expandableContent={(rowId: string) => {
                  const event = eventsById.get(rowId);
                  if (event) {
                    return (
                      <Box
                        sx={{
                          padding: theme.spacing(3, 2),
                        }}
                      >
                        <EventDetails event={event} readonly={true} />
                      </Box>
                    );
                  }
                }}
              />

              <ColumnsModal
                open={state.modalOpen}
                handleClose={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: false });
                }}
                columns={allPossibleColumns}
                currentColumns={state.columns}
                onSave={(updatedColumns) => {
                  dispatch({ type: "SAVE_COLUMNS", payload: updatedColumns });
                }}
              />
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};
