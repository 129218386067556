import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import {
  Box,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import * as React from "react";

import { useAuth } from "@/providers";
import {
  RobotoButton,
  RobotoLogo,
  RobotoTypography,
} from "@/shared/components";
import { OrgRecord } from "@/shared/domain/orgs";
import { RobotoConflictException } from "@/shared/http";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { CreateOrganizationResponse } from "@/shared/services/ApiService";
import { RobotoAPICall, orgsEndpoint } from "@/types";

import { SignInFormState, SignUpFormState } from "../types";

import { AuthCard } from "./AuthCard";
import { emailDenyList } from "./data";
import { LegalFooter } from "./LegalFooter";

interface ConfigureOrganizationFormProps {
  isVisible: boolean;
  goBackPressed: () => void;
  formState: SignUpFormState | SignInFormState;
  onSuccess: (allowEmailDomain: boolean, organizationName: string) => void;
}

const buttonWidth = "250px";

export const ConfigureOrganizationForm: React.FC<
  ConfigureOrganizationFormProps
> = ({ isVisible, goBackPressed, onSuccess, formState }) => {
  const theme = useTheme();

  const [allowEmailDomain, setAllowEmailDomain] =
    React.useState<boolean>(false);
  const [organizationName, setOrganizationName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string | null>(null);

  const createOrgAPICall: RobotoAPICall = {
    endpoint: orgsEndpoint,
    method: "POST",
    requestBody: JSON.stringify({
      name: organizationName,
      org_type: "team",
      bind_email_domain: allowEmailDomain,
      data_region: formState.robotoRegion,
    }),
  };

  const {
    error: errorState,
    loading,
    initiateRequest: initiateCreateOrgAPICall,
  } = useLazyAPICall<CreateOrganizationResponse>();

  const { setCurrentOrganization, getUserIdToken, refreshOrgList } = useAuth();

  useEffect(() => {
    const getUser = async () => {
      const userIdToken = await getUserIdToken();
      setEmail(userIdToken?.email ?? null);
    };

    void getUser();
  }, [getUserIdToken]);

  if (!isVisible) {
    return null;
  }

  const onClick = async () => {
    const { error, data } = await initiateCreateOrgAPICall(createOrgAPICall);

    void refreshOrgList();

    if (!error) {
      setCurrentOrganization(data?.data as OrgRecord);
      onSuccess(allowEmailDomain, organizationName);
    }
  };

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <Box sx={{ textAlign: "center", mb: theme.spacing(3) }}>
          {formState.workMode === "individual" ? (
            <PersonRoundedIcon
              color="primary"
              sx={{
                fontSize: "5rem",
                display: {
                  xs: "none",
                  sm: "inline",
                },
              }}
            />
          ) : (
            <GroupRoundedIcon
              color="primary"
              sx={{
                fontSize: "5rem",
                display: {
                  xs: "none",
                  sm: "inline",
                },
              }}
            />
          )}

          <RobotoTypography
            variant="h5"
            textAlign={"center"}
            sx={{
              marginBottom: theme.spacing(2),
            }}
          >
            Configure your{" "}
            {formState.workMode === "individual" ? "account" : "organization"}
          </RobotoTypography>
          <RobotoTypography variant="body2" textAlign={"center"}>
            {formState.workMode === "individual"
              ? "Give your account a name so you can easily find it later"
              : "After creating an organization, you can invite other team members to join"}
          </RobotoTypography>
        </Box>

        <TextField
          data-cy={"organization-name-input"}
          label={
            formState.workMode === "individual"
              ? "Account Name"
              : "Organization Name"
          }
          sx={{ marginBottom: theme.spacing(2) }}
          value={organizationName}
          size="small"
          onChange={(e) => setOrganizationName(e.target.value)}
          helperText={
            "Give your " +
            (formState.workMode === "individual" ? "account" : "organization") +
            " a clear name e.g. Tasty Robots"
          }
          autoFocus
          inputProps={{ maxLength: 40 }}
        />

        {email &&
          !emailDenyList.has(email.split("@")[1]) &&
          formState.workMode !== "individual" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginBottom: theme.spacing(2),
              }}
            >
              <Checkbox
                sx={{ marginLeft: -1.5 }}
                checked={allowEmailDomain}
                onChange={() => setAllowEmailDomain(!allowEmailDomain)}
              />
              <Typography variant="body2" component="span">
                <>
                  Allow anyone with an{" "}
                  <span style={{ fontWeight: 500 }}>
                    @{email.split("@")[1]}
                  </span>{" "}
                  email to join
                </>
              </Typography>
            </Box>
          )}

        {loading ? (
          <CircularProgress size="1.75rem" />
        ) : (
          <>
            <RobotoButton
              data-cy={"continue-configure-organization"}
              eventName={"GoToNextStep"}
              eventProperties={{ currentStep: "ConfigureOrganization" }}
              disabled={organizationName.length < 3}
              variant={"contained"}
              sx={{ width: buttonWidth, marginBottom: theme.spacing(2) }}
              onClick={() => {
                void onClick();
              }}
            >
              Continue
            </RobotoButton>

            <RobotoButton
              eventName={"GoToPreviousStep"}
              eventProperties={{ currentStep: "ConfigureOrganization" }}
              sx={{ width: buttonWidth }}
              onClick={goBackPressed}
            >
              Go back a step
            </RobotoButton>
          </>
        )}

        {errorState && (
          <RobotoTypography
            variant={"caption"}
            textAlign={"center"}
            sx={{ color: theme.palette.error.main }}
          >
            {errorState.errorCode === RobotoConflictException.name
              ? "Another organization already lets users join with this email domain. Please uncheck the option and try again, or get in touch with us for help."
              : errorState.message}
          </RobotoTypography>
        )}
      </AuthCard>
      <LegalFooter />
    </>
  );
};
