import { SignUpPage } from "@/features";
import { Page } from "@/shared/components/Page";

export default function SignUp() {
  return (
    <Page title={"Roboto - Sign Up"}>
      <SignUpPage />
    </Page>
  );
}
