import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Collapse, useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import {
  RobotoCheckbox,
  RobotoTableCell,
  RobotoTableRow,
  TagList,
  TooltipIconButton,
  UserLink,
} from "@/shared/components";
import { EventScope } from "@/shared/components/EventScope";
import { EventRecord } from "@/shared/domain/events";
import { nanoSecToDate } from "@/utils/time";

import { EventDetails } from "./EventDetails";

interface EventRowProps {
  event: EventRecord;
  isSelected: boolean;
  numColumns: number;
  onSelect: (event: EventRecord) => void;
  datasetId: string;
}

export const EventRow: React.FC<EventRowProps> = ({
  event,
  isSelected,
  numColumns,
  onSelect,
  datasetId,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const queryClient = useQueryClient();

  const toggleExpandingContent = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <RobotoTableRow hover>
        <RobotoTableCell
          sx={{
            padding: "0px",
            width: "42.5px",
            maxWidth: "42.5px",
          }}
        >
          <RobotoCheckbox
            sx={{
              ml: theme.spacing(1),
              display: {
                xs: "none",
                sm: "inline-flex",
              },
            }}
            checked={isSelected}
            onChange={() => onSelect(event)}
            color="default"
            inputProps={{ "aria-label": "checkbox" }}
          />
        </RobotoTableCell>
        <RobotoTableCell onClick={toggleExpandingContent}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1.5),
            }}
          >
            <TooltipIconButton
              title={expanded ? "Collapse" : "Expand"}
              onClick={toggleExpandingContent}
              sx={{
                padding: theme.spacing(0.25),
              }}
            >
              <ExpandCircleDownOutlinedIcon
                fontSize="small"
                sx={{
                  transform: expanded ? "none" : "rotate(270deg)",
                }}
                color="primary"
              />
            </TooltipIconButton>
            {event.name}
          </Box>
        </RobotoTableCell>
        <RobotoTableCell onClick={toggleExpandingContent}>
          {event.event_id}
        </RobotoTableCell>
        <RobotoTableCell onClick={toggleExpandingContent}>
          {nanoSecToDate(event.start_time).toLocaleString()}
        </RobotoTableCell>
        <RobotoTableCell onClick={toggleExpandingContent}>
          <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
            <PersonIcon fontSize="small" color="primary" />
            <UserLink userId={event.created_by} />
          </Box>
        </RobotoTableCell>

        <RobotoTableCell onClick={toggleExpandingContent}>
          <EventScope event={event} />
        </RobotoTableCell>

        <RobotoTableCell onClick={toggleExpandingContent}>
          <TagList maxDisplayCount={3} tags={event?.tags} />
        </RobotoTableCell>
      </RobotoTableRow>

      <RobotoTableRow>
        <RobotoTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderWidth: expanded ? "1px" : 0,
          }}
          colSpan={numColumns}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <EventDetails
              event={event}
              onUpdateEvent={(newEvent) => {
                queryClient.setQueryData<EventRecord[] | undefined>(
                  ["datasetEvents", datasetId],
                  (prev) =>
                    prev?.map((event) =>
                      event.event_id === newEvent.event_id ? newEvent : event,
                    ),
                );
              }}
            />
          </Collapse>
        </RobotoTableCell>
      </RobotoTableRow>
    </>
  );
};
