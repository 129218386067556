import { useParams } from "react-router-dom";

import { DatasetExplorerPage } from "@/features";
import { NavigationPage } from "@/shared/components";

const DatasetExplorer: React.FC = () => {
  const { datasetPath: datasetId } = useParams();

  return (
    <NavigationPage title={`Roboto - Dataset - ${datasetId}`}>
      <DatasetExplorerPage datasetId={datasetId} />
    </NavigationPage>
  );
};

export default DatasetExplorer;
