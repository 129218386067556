import { CreateDatasetPage } from "@/features/datasets";
import { NavigationPage } from "@/shared/components";

const DatasetCreationPage: React.FC = () => {
  return (
    <NavigationPage title={"Roboto - Create Dataset"}>
      <CreateDatasetPage />
    </NavigationPage>
  );
};

export default DatasetCreationPage;
