import * as React from "react";

import { DatasetDetailSidebarContent } from "@/shared/components/DatasetDetailSidebarContent";
import { QueryTarget } from "@/shared/domain/query";
import { Dataset } from "@/types";

interface SearchResultsSidebarContentProps {
  selection?: {
    result: unknown;
    resultType?: QueryTarget;
  };
  onTagUpdateError?: (error: Error) => void;
}

export const SearchResultsSidebarContent: React.FC<
  SearchResultsSidebarContentProps
> = ({ selection, onTagUpdateError }) => {
  if (selection?.resultType === undefined) {
    return null;
  }

  if (selection.resultType === QueryTarget.Datasets) {
    return (
      <DatasetDetailSidebarContent
        dataset={selection?.result as Dataset}
        onTagUpdateError={onTagUpdateError}
      />
    );
  }
};
