import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { useAuth, useNavigation } from "@/providers";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIServiceError, Dataset, datasetsEndpoint } from "@/types";

import { MetadataForm, MetadataView } from "../Metadata";
import { RobotoButton } from "../RobotoButton";
import { RobotoTooltip } from "../RobotoTooltip";
import { AutocompleteType } from "../TagAndMetadataAutocomplete";
import { TagInput, Tags, TagsGroup } from "../tags";

interface CreateDatasetProps {
  handleClose?: () => void;
  setErrorText?: (arg: string) => void;
  onError?: (error: APIServiceError) => void;
}

export const CreateDataset: React.FC<CreateDatasetProps> = ({
  handleClose,
  setErrorText,
  onError,
}) => {
  const theme = useTheme();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [tags, setTags] = React.useState<string[]>([]);
  const [name, setName] = React.useState<string>("");
  const [metadata, setMetadata] = React.useState<Record<string, unknown>>({});
  const { currentOrganization } = useAuth();
  const { goto } = useNavigation();

  const { initiateRequest: createDatasetReq } = useLazyAPICall<{
    data: Dataset;
  }>();

  const createDataset = async () => {
    setLoading(true);

    const emptyName = name.trim() === "";

    const { error, data } = await createDatasetReq({
      method: "POST",
      endpoint: datasetsEndpoint,
      requestBody: JSON.stringify({
        tags: tags,
        metadata: metadata,
        name: emptyName ? undefined : name,
        administrator: "Roboto",
        storage_location: "S3",
      }),
      orgId: currentOrganization?.org_id,
    });

    if (error && setErrorText) {
      setErrorText(error.message);
    }
    if (error && onError) {
      onError(error);
    }
    if (!error && data) {
      goto.dataset({
        datasetId: data.data.dataset_id,
      });
    }
    if (handleClose) {
      handleClose();
    }
    setLoading(false);
  };

  return (
    <Box>
      <Box sx={{ padding: theme.spacing(1) }}>
        <Box sx={{ mb: theme.spacing(1) }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle2">Name (optional)</Typography>
            <RobotoTooltip title="120 character limit">
              <HelpOutlineIcon
                sx={{ fontSize: "1rem", ml: 1, cursor: "pointer" }}
                color="info"
              />
            </RobotoTooltip>
          </Box>
          <Typography variant="caption">
            Add a name for this dataset.
          </Typography>
        </Box>

        <TextField
          id="outlined-action-desc"
          placeholder="Enter a name"
          size="small"
          multiline
          fullWidth
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
          inputProps={{
            style: { fontSize: "0.9rem" },
            maxLength: 120,
          }}
          sx={{
            width: "85%",
          }}
        />

        <Divider sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }} />

        <Box sx={{ mb: theme.spacing(1) }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle2">Tags (optional)</Typography>
          </Box>
          <Typography variant="caption">
            Add tags to your dataset so it&apos;s easier to find later.
          </Typography>
        </Box>

        <TagsGroup>
          <Tags
            tags={tags}
            onDeleteTag={(tag) => {
              setTags((prev) => prev.filter((t) => t !== tag));
            }}
            truncateText={false}
          />
          <TagInput
            autocompleteType={AutocompleteType.DatasetTags}
            onAddTag={(tag) => {
              setTags((prev) => [...prev, tag]);
            }}
            tags={tags}
          />
        </TagsGroup>

        <Divider sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }} />

        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle2">Metadata (optional)</Typography>
          </Box>
          <Typography variant="caption">
            Add key-value pairs to capture any key details, e.g. softwareVersion
            = 1.0.3.
          </Typography>
        </Box>

        <MetadataView metadata={metadata} onChange={setMetadata} />
        <MetadataForm
          metadata={metadata}
          autocompleteType={AutocompleteType.DatasetMetadataKeys}
          onAddMetadata={(key, value) => {
            setMetadata({
              ...metadata,
              [key]: value,
            });
          }}
        />

        <Divider sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }} />

        <Box
          sx={{
            mt: theme.spacing(1),
            display: "flex",
            alignItems: "center",
          }}
        >
          <RobotoButton
            eventName={"CreateDatasetFormSubmitted"}
            eventProperties={{ source: "CreateDatasetPopover" }}
            variant={"contained"}
            color="secondary"
            onClick={() => {
              void createDataset();
            }}
          >
            Create Dataset
          </RobotoButton>
          {loading && (
            <CircularProgress sx={{ ml: theme.spacing(1.5) }} size="1rem" />
          )}
        </Box>
      </Box>
    </Box>
  );
};
