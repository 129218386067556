import { Alert, AlertTitle, Box, Paper, useTheme } from "@mui/material";
import * as React from "react";

import { useNavigation } from "@/providers";
import { TopicRecord } from "@/shared/domain/topics";
import { actions, useVizDispatch } from "@/shared/state/visualization";
import { SearchQueryBody } from "@/types";

import { MessagePathsDetail } from "../MessagePathsDetail";
import { RobotoDataGrid } from "../RobotoDataGrid";

import { ColumnsModal } from "./ColumnsModal";
import {
  TablePageAction,
  TablePageState,
  tablePageReducer,
} from "./stateReducer";
import { createRows, retrieveMetadataColumns } from "./tableLogic";

const initialTopicsQuery: SearchQueryBody = {
  sort_by: "created",
  sort_direction: "DESC",
};

const possibleStandardFilesColumns = [
  "topic_id",
  "topic_name",
  "created",
  "created_by",
  "modified",
  "modified_by",
  "schema_name",
  "schema_version",
  "org_id",
  "message_count",
  "start_time",
  "end_time",
];

const sortableColumns = new Set<string>();

interface TopicsTableProps {
  topicRecords: TopicRecord[];
  loading: boolean;
  selectedRows?: Set<string>;
  setSelectedRows?: (arg: Set<string>) => void;
  onRowSingleClick?: (topic: TopicRecord) => void;
  variant?: "elevation" | "outlined";
  containerStyle?: React.CSSProperties;
}

export const TopicsTable: React.FC<TopicsTableProps> = ({
  topicRecords,
  loading,
  selectedRows,
  setSelectedRows = () => {},
  onRowSingleClick = () => {},
  variant,
  containerStyle,
}) => {
  const theme = useTheme();

  const topicsById = new Map<string, TopicRecord>(
    topicRecords.map((topic) => {
      const idToStr = topic.topic_id.toString();
      return [idToStr, topic];
    }),
  );

  const { goto } = useNavigation();
  const vizDispatch = useVizDispatch();

  const [selectedRowsToggle, setSelectedRowsToggle] =
    React.useState<boolean>(false);

  const [state, dispatch] = React.useReducer<
    React.Reducer<TablePageState, TablePageAction>
  >(tablePageReducer, {
    modalOpen: false,
    rightClickedRow: null,
    sidebar: { isOpen: false, dataset: null },
    columns: ["topic_id", "topic_name", "created", "message_count"],
    tableConfig: { page: 0, rowsPerPage: 25 },
    snackbar: { isOpen: false, message: "" },
    sortColumnIndex: 1,
    sortOrder: "descending",
    lastSearchQuery: initialTopicsQuery,
    selectedRows: new Set<string>(),
  });

  const pageData = React.useMemo(() => {
    return topicRecords.slice(
      state.tableConfig.page * state.tableConfig.rowsPerPage,
      state.tableConfig.page * state.tableConfig.rowsPerPage +
        state.tableConfig.rowsPerPage,
    );
  }, [topicRecords, state.tableConfig]);

  const { rows, allPossibleColumns } = React.useMemo(() => {
    const possibleMetadataColumns: string[] =
      retrieveMetadataColumns(pageData) ?? [];

    const rows = createRows(pageData, state.columns, possibleMetadataColumns);

    const allPossibleColumns = possibleStandardFilesColumns
      .concat(possibleMetadataColumns)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

    return { rows, allPossibleColumns };
  }, [pageData, state.columns]);

  const noSearchResults =
    Object.keys(state.lastSearchQuery).length > 0 && topicRecords.length === 0;

  React.useEffect(() => {
    if (selectedRows && selectedRows.size === 0) {
      setSelectedRowsToggle((prevToggle) => !prevToggle);
      dispatch({
        type: "SET_SELECTED_ROWS",
        payload: { selectedRows: new Set<string>() },
      });
    }
  }, [selectedRows]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: "100%",
        }}
      >
        <Paper
          sx={{
            mt: theme.spacing(2),
          }}
          variant={variant}
        >
          {noSearchResults && (
            <Alert severity="info">
              <AlertTitle>Topics</AlertTitle>
              <>
                No topics match your search criteria. Try changing your search
                terms and try again.
              </>
            </Alert>
          )}

          {!noSearchResults && (
            <>
              <RobotoDataGrid
                sortableColumns={sortableColumns}
                sortingOrder={state.sortOrder}
                sortingColumnIndex={state.sortColumnIndex}
                page={state.tableConfig.page}
                rowsPerPage={state.tableConfig.rowsPerPage}
                nextPageButtonDisabled={
                  (state.tableConfig.page + 1) *
                    state.tableConfig.rowsPerPage >=
                  topicRecords.length
                }
                currentRowLength={topicRecords.length}
                containerStyle={containerStyle}
                loading={loading}
                columnNames={state.columns}
                rows={rows}
                onPageChange={(newPage, rowsPerPage) => {
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: { page: newPage, rowsPerPage: rowsPerPage },
                  });
                }}
                onRowsPerPageChange={(_page, newRowsPerPage) => {
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: {
                      page: 0,
                      rowsPerPage: newRowsPerPage as 10 | 25 | 50,
                    },
                  });
                }}
                onColumnSortClicked={() => {
                  // do nothing
                }}
                onAddColumnClick={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: true });
                }}
                onRowDoubleClick={(rowId) => {
                  const t = topicsById.get(rowId) || null;
                  if (t && t.association) {
                    goto.file({
                      fileId: t.association.association_id,
                      beforeNavigation: () =>
                        vizDispatch(
                          actions.putFiles([
                            { fileId: t.association.association_id },
                          ]),
                        ),
                    });
                  }
                }}
                onRowSingleClick={(rowId: string) => {
                  const topicRecord = topicsById.get(rowId);
                  if (onRowSingleClick && topicRecord) {
                    onRowSingleClick(topicRecord);
                  }
                }}
                onSelectedRowsChange={(selectedRows) => {
                  setSelectedRows(selectedRows);
                  dispatch({
                    type: "SET_SELECTED_ROWS",
                    payload: { selectedRows: selectedRows },
                  });
                }}
                isRowSelectable={false}
                isRowExpandable={true}
                clearSelectedRowsToggle={selectedRowsToggle}
                expandableContent={(rowId: string) => {
                  const partialTopicRecord = topicsById.get(rowId);
                  if (partialTopicRecord) {
                    return (
                      <Box
                        sx={{
                          padding: theme.spacing(3, 2),
                        }}
                      >
                        <MessagePathsDetail
                          topicId={partialTopicRecord.topic_id}
                        />
                      </Box>
                    );
                  }
                }}
              />
              <ColumnsModal
                open={state.modalOpen}
                handleClose={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: false });
                }}
                columns={allPossibleColumns}
                currentColumns={state.columns}
                onSave={(updatedColumns) => {
                  dispatch({ type: "SAVE_COLUMNS", payload: updatedColumns });
                }}
              />
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};
