import { Alert, Box, useTheme } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import * as React from "react";

import { CommentsBox } from "@/shared/components";

import { DatasetHeader } from "../components";
import { DatasetTabs } from "../components/DatasetTabs";

import { DatasetRoutes } from "./DatasetRoutes";

interface DatasetExplorerPageProps {
  datasetId: string | undefined;
}

export const DatasetExplorerPage: React.FC<DatasetExplorerPageProps> = ({
  datasetId,
}) => {
  const theme = useTheme();

  if (!datasetId) {
    return <Alert severity="error">Missing dataset id in path</Alert>;
  }

  return (
    <ErrorBoundary
      fallback={(props) => {
        if (props.error instanceof Error) {
          return <Alert severity="error">{props.error.message}</Alert>;
        }

        return (
          <Alert severity="error">
            Something went wrong. Please contact{" "}
            <a href="mailto:support@roboto.ai">support@roboto.ai</a> if this
            problem persists.
          </Alert>
        );
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <DatasetHeader datasetId={datasetId} showTags />
        <DatasetTabs datasetId={datasetId} />
        <DatasetRoutes datasetId={datasetId} />
        <Box sx={{ mt: theme.spacing(2) }}>
          <CommentsBox entityType={"dataset"} entityId={datasetId} />
        </Box>
      </section>
    </ErrorBoundary>
  );
};
