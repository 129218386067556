import { Divider, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import { RobotoStyledTab, RobotoStyledTabs } from "@/shared/components";

import {
  useActiveDatasetTab,
  DatasetTab,
} from "../hooks/useActiveDatasetTabFromUrl";

import { DatasetActionStatus } from "./DatasetActionStatus";

interface DatasetTabsProps {
  datasetId: string;
}

export function DatasetTabs({ datasetId }: DatasetTabsProps) {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useActiveDatasetTab();
  return (
    <div
      style={{
        marginTop: theme.spacing(1),
      }}
    >
      <RobotoStyledTabs
        value={activeTab}
        onChange={(_event, nextTab: DatasetTab) => setActiveTab(nextTab)}
      >
        <RobotoStyledTab
          value={DatasetTab.Files}
          label="Files"
          component={Link}
          to={""}
          replace
        />
        <RobotoStyledTab
          value={DatasetTab.Topics}
          label="Topics"
          component={Link}
          to={"topics"}
          replace
        />
        <RobotoStyledTab
          value={DatasetTab.Events}
          label="Events"
          component={Link}
          to={"events"}
          replace
        />
        <RobotoStyledTab
          icon={<DatasetActionStatus datasetId={datasetId} />}
          iconPosition="end"
          value={DatasetTab.Actions}
          label="Actions"
          component={Link}
          to={"actions"}
          replace
        />
        <RobotoStyledTab
          value={DatasetTab.Details}
          label="Details"
          component={Link}
          to={"details"}
          replace
        />
        <RobotoStyledTab
          value={DatasetTab.Permissions}
          label="Permissions"
          component={Link}
          to={"permissions"}
          replace
        />
      </RobotoStyledTabs>
      <Divider
        sx={{
          borderColor: theme.border.color,
        }}
      />
    </div>
  );
}
