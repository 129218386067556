import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, useTheme } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { OrgUpdateTierButton } from "@/features/orgs/components/OrgUpdateTierButton.tsx";
import { useAuth } from "@/providers";
import { useDomainServices } from "@/providers/DomainServices";
// TODO break up OrganizationSettings and put the pieces into /shared
import { OrganizationSettings } from "@/Settings/OrganizationSettings";
import { NavigationPage } from "@/shared/components";
import { SystemUserOnly } from "@/shared/components/SystemUserOnly";
import { OrgRecord } from "@/shared/domain/orgs";

export function Org() {
  const theme = useTheme();
  const { currentUser, setCurrentOrganization } = useAuth();
  const { orgId } = useParams();

  const { orgs } = useDomainServices();

  const [org, setOrg] = React.useState<OrgRecord>();

  React.useEffect(() => {
    if (!orgId) {
      return;
    }
    const fetchOrg = async () => {
      const fetchedOrg = await orgs.getOrg(orgId);
      setOrg(fetchedOrg);
    };
    void fetchOrg();
  }, [orgId, orgs]);

  return (
    <NavigationPage title={`Roboto - Orgs - ${orgId}`}>
      <SystemUserOnly>
        {org && (
          <>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(2),
              }}
            >
              <Button
                variant="contained"
                disableElevation
                onClick={() => setCurrentOrganization(org)}
                style={{
                  textTransform: "none",
                }}
                startIcon={<VisibilityIcon fontSize="small" />}
              >
                View As
              </Button>
              <OrgUpdateTierButton
                orgId={org.org_id}
                currentOrgTier={org.tier}
                onSuccess={(updatedOrg) => setOrg(updatedOrg)}
              />
            </Box>
            <Box mt={theme.spacing(2)}>
              <OrganizationSettings
                currentOrg={org}
                currentUser={currentUser}
              />
            </Box>
          </>
        )}
      </SystemUserOnly>
    </NavigationPage>
  );
}
