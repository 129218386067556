import { Route, Routes } from "react-router-dom";

import { Invocations } from "@/Actions/components";
import { useAuth } from "@/providers";

import { DatasetDetails, DatasetFileBrowser } from "../components";
import { DatasetEvents } from "../components/DatasetEvents";
import { DatasetPermissionDetails } from "../components/DatasetPermissionDetails";
import { DatasetTopics } from "../components/DatasetTopics";

interface DatasetRoutesProps {
  datasetId: string;
}

export function DatasetRoutes({ datasetId }: DatasetRoutesProps) {
  const { currentOrganization } = useAuth();
  return (
    <Routes>
      <Route
        path="files/*"
        element={<DatasetFileBrowser datasetId={datasetId} />}
      />
      <Route path="topics" element={<DatasetTopics datasetId={datasetId} />} />
      <Route path="events" element={<DatasetEvents datasetId={datasetId} />} />
      <Route
        path="actions"
        element={
          <Invocations datasetId={datasetId} currentOrg={currentOrganization} />
        }
      />
      <Route
        path="details"
        element={
          <DatasetDetails
            datasetId={datasetId}
            currentOrg={currentOrganization}
          />
        }
      />
      <Route
        path="permissions"
        element={<DatasetPermissionDetails datasetId={datasetId} />}
      />
      <Route path="*" element={<DatasetFileBrowser datasetId={datasetId} />} />
    </Routes>
  );
}
