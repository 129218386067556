import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

import { CreateDataset } from "@/shared/components/CreateDataset";

export const CreateDatasetPage: React.FC = () => {
  //
  const theme = useTheme();

  const [errorText, setErrorText] = React.useState<string>("");

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          borderBottom: "unset",
          "& a": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <Link to="/datasets">Datasets</Link>,
        <Typography
          sx={{ fontSize: "1.125rem", fontWeight: "500" }}
          color="text.primary"
        >
          Create Dataset
        </Typography>
      </Breadcrumbs>

      <Box>
        <Box
          component={Paper}
          variant="outlined"
          sx={{ p: theme.spacing(2), mt: theme.spacing(3), background: "none" }}
        >
          <Box
            sx={{
              mb: theme.spacing(2),
            }}
          >
            {errorText ? (
              <Alert severity="error">{errorText}</Alert>
            ) : (
              <Alert severity="info">
                Complete the form below to create a new dataset.
              </Alert>
            )}
          </Box>
          <Box
            sx={{
              maxWidth: "60ch",
            }}
          >
            <CreateDataset setErrorText={setErrorText} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
