import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Box, Checkbox, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import {
  RobotoButton,
  RobotoLogo,
  RobotoTypography,
} from "@/shared/components";

import { AuthCard } from "./AuthCard";
import { LegalFooter } from "./LegalFooter";

const buttonWidth = 215;

interface HowDoYouWorkFormProps {
  isVisible: boolean;
  continueClicked: (workMode: "individual" | "team") => void;
  goBackPressed: () => void;
}

export const HowDoYouWorkForm: React.FC<HowDoYouWorkFormProps> = ({
  isVisible,
  continueClicked,
  goBackPressed,
}) => {
  const theme = useTheme();

  const [selectedMode, setSelectedMode] = useState<
    "team" | "individual" | undefined
  >(undefined);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <Box>
          <RobotoTypography
            variant="h5"
            textAlign={"center"}
            sx={{ marginBottom: theme.spacing(2) }}
          >
            How do you work?
          </RobotoTypography>
          <RobotoTypography variant="body2" textAlign={"center"}>
            Let us know how you plan to use Roboto, so we can help you set up
            your account.
          </RobotoTypography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: theme.spacing(3),
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          <WorkStyleCard
            mode={"individual"}
            icon={<PersonRoundedIcon color="primary" fontSize={"large"} />}
            selected={selectedMode === "individual"}
            workStyleSelected={() => {
              setSelectedMode("individual");
            }}
          />
          <WorkStyleCard
            mode={"team"}
            icon={<GroupRoundedIcon color="primary" fontSize={"large"} />}
            selected={selectedMode === "team"}
            workStyleSelected={() => {
              setSelectedMode("team");
            }}
          />
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            mt: theme.spacing(2),
            width: { xs: "100%", sm: buttonWidth },
          }}
        >
          <RobotoButton
            data-cy={"continue-button"}
            eventName={"GoToNextStep"}
            eventProperties={{ currentStep: "HowDoYouWork" }}
            disabled={selectedMode === undefined}
            variant="contained"
            sx={{
              marginBottom: theme.spacing(2),
              width: { xs: "100%", sm: buttonWidth },
            }}
            onClick={() => {
              continueClicked(selectedMode as "individual" | "team");
            }}
          >
            Continue
          </RobotoButton>

          <RobotoButton
            eventName={"GoToPreviousStep"}
            eventProperties={{ currentStep: "HowDoYouWork" }}
            onClick={goBackPressed}
            sx={{
              width: { xs: "100%", sm: buttonWidth },
            }}
          >
            Go back a step
          </RobotoButton>
        </Box>
      </AuthCard>
      <LegalFooter />
    </>
  );
};

interface WorkStyleCardProps {
  mode: "team" | "individual";
  selected: boolean;
  workStyleSelected: () => void;
  icon: React.ReactElement;
}

const WorkStyleCard: React.FC<WorkStyleCardProps> = ({
  mode,
  selected,
  workStyleSelected,
  icon,
}) => {
  const theme = useTheme();

  const headerText = mode === "team" ? "Team" : "Individual";

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: "1 1 0",
      }}
    >
      {icon}
      <RobotoTypography variant="h6" textAlign={"center"}>
        {headerText}
      </RobotoTypography>
      <Checkbox
        data-cy={mode + "-checkbox"}
        checked={selected}
        onChange={workStyleSelected}
      />
    </Paper>
  );
};
