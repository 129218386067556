import { useQuery, UseQueryResult } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { PaginatedResponse } from "@/shared/http";

export function useQueryResults<T>(
  queryId: string | undefined,
  nextPageToken: string | undefined,
  pageSize: number = 1000,
  ttlMillis: number = 1000,
): UseQueryResult<PaginatedResponse<T>, Error> {
  const { queryService } = React.useContext(DomainServicesContext);

  return useQuery<PaginatedResponse<T>, Error>({
    queryKey: ["queryResults", queryId, nextPageToken, pageSize],
    queryFn: ({ signal }) => {
      if (queryId === undefined) {
        return Promise.reject(new Error("Invalid queryId"));
      }

      return queryService.getQueryResults(queryId, {
        abortSignal: signal,
        nextPageToken: nextPageToken,
        pageSize: pageSize,
      });
    },
    enabled: queryId !== undefined,
    staleTime: ttlMillis || 1000,
  });
}
