import {
  TableBody,
  TableRow,
  Skeleton,
  Alert,
  AlertTitle,
} from "@mui/material";

import { TopicSequenceRow } from "@/features/dataset/components/DatasetTopics/TopicSequenceRow";
import { RobotoTableCell } from "@/shared/components";
import { useDatasetTopics } from "@/shared/domain/topics/hooks";
import { TopicSequence } from "@/shared/domain/topics/TopicSequence.ts";

import styles from "./DatasetTopicTableBody.module.css";

interface DatasetTopicTableBodyProps {
  datasetId: string;
  numColumns: number;
}

export function DatasetTopicTableBody({
  datasetId,
  numColumns,
}: DatasetTopicTableBodyProps) {
  const topics = useDatasetTopics(datasetId, true);

  if (topics.isLoading) {
    return (
      <TableBody>
        {Array.from({ length: 3 }, (_, rowIndex) => (
          <TableRow key={`skeleton-row-${rowIndex}`}>
            {Array.from({ length: numColumns }, (_, colIndex) => (
              <RobotoTableCell key={`skeleton-cell-${rowIndex}-${colIndex}`}>
                <Skeleton animation="pulse" variant="text" />
              </RobotoTableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  }

  if (topics.error) {
    return (
      <TableBody>
        <TableRow>
          <RobotoTableCell colSpan={numColumns}>
            <Alert severity="error" className={styles.alert}>
              <AlertTitle>Failed to fetch topics</AlertTitle>
              Please contact{" "}
              <a href="mailto:support@roboto.ai">support@roboto.ai</a> if this
              problem persists.
            </Alert>
          </RobotoTableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (topics.data === undefined || topics.data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <RobotoTableCell colSpan={numColumns}>
            <Alert severity="warning" className={styles.alert}>
              No topics have been ingested from the files in this dataset yet.
            </Alert>
          </RobotoTableCell>
        </TableRow>
      </TableBody>
    );
  }

  const topicSequences = TopicSequence.fromTopics(topics.data).sort((a, b) =>
    a.topic_name.localeCompare(b.topic_name),
  );

  return (
    <TableBody>
      {topicSequences.map((topicSequence) => (
        <TopicSequenceRow
          key={topicSequence.topic_name}
          topicSequence={topicSequence}
          numColumns={numColumns}
        />
      ))}
    </TableBody>
  );
}
